import React from 'react';

import AltButton from '../../../../library/alt-button/alt-button';
import AltButtonGroup from '../../../../library/alt-button/components/alt-button-group/alt-button-group';
import {
  CalendarAltSolidIcon,
  ExchangeAltSolidIcon,
  LocationArrowSolidIcon,
  ShareAltSolidIcon
} from '../../../../shared/icons/icons';

import {
  useModal,
  useScheduleAppointment
} from '../../../../shared/hooks/hooks';

import { getGoogleMapsLink } from '../../../../shared/utils/store/store';

import {
  DtAddressData,
  Maybe,
  StoreReviewRatingData
} from '../../../../shared/types/graphql-types';
import { SHARE_STORE } from '../../../../shared/constants/modals';

interface StoreQuickViewAltCtasProps {
  address: DtAddressData;
  distance?: number;
  legacyStoreCode?: string;
  onChangeStore?: (changeStore: boolean) => void;
  onGetDirectionsClick?: () => void;
  onScheduleAppointmentClick?: () => void;
  onShareClick?: () => void;
  rating?: Maybe<StoreReviewRatingData>;
  showAltChangeStoreCTA?: boolean;
  showAltDirectionsCTA?: boolean;
  showAltScheduleAppointmentCTA?: boolean;
  showAltShareStoreCTA?: boolean;
  storeCode: string;
}

function StoreQuickViewAltCtas(props: StoreQuickViewAltCtasProps) {
  const {
    address,
    distance,
    legacyStoreCode,
    onChangeStore,
    onGetDirectionsClick,
    onScheduleAppointmentClick,
    onShareClick,
    rating,
    showAltChangeStoreCTA,
    showAltDirectionsCTA,
    showAltScheduleAppointmentCTA,
    showAltShareStoreCTA,
    storeCode
  } = props;
  const { openModal } = useModal();
  const { handleScheduleAppointment } = useScheduleAppointment();

  const { town, region, postalCode, line1 } = address || {};
  const regionCode = region?.isocodeShort;
  const showAltCtas =
    showAltDirectionsCTA ||
    showAltScheduleAppointmentCTA ||
    showAltShareStoreCTA ||
    showAltChangeStoreCTA;

  function onDirectionsClick() {
    onGetDirectionsClick?.();

    if (town && regionCode && postalCode) {
      return window.open(
        getGoogleMapsLink(town, regionCode, postalCode, line1),
        '_blank'
      );
    }
  }

  return showAltCtas ? (
    <AltButtonGroup>
      {showAltDirectionsCTA && (
        <AltButton icon={LocationArrowSolidIcon} onClick={onDirectionsClick}>
          Directions
        </AltButton>
      )}

      {showAltScheduleAppointmentCTA && (
        <AltButton
          icon={CalendarAltSolidIcon}
          onClick={() => {
            handleScheduleAppointment(storeCode);
            onScheduleAppointmentClick?.();
          }}
        >
          Appointment
        </AltButton>
      )}

      {showAltShareStoreCTA && (
        <AltButton
          icon={ShareAltSolidIcon}
          onClick={() => {
            onShareClick?.();
            openModal(SHARE_STORE, {
              store: {
                address,
                code: storeCode,
                distance,
                legacyStoreCode,
                rating
              }
            });
          }}
        >
          Share
        </AltButton>
      )}

      {showAltChangeStoreCTA && (
        <AltButton
          icon={ExchangeAltSolidIcon}
          onClick={() => onChangeStore?.(true)}
        >
          Change Store
        </AltButton>
      )}
    </AltButtonGroup>
  ) : null;
}

export default StoreQuickViewAltCtas;
