import React from 'react';

import Button from '../../../../library/button/button';
import ButtonGroup from '../../../../library/button/components/button-group/button-group';

import {
  useFitment,
  useMyStore,
  useScheduleAppointment
} from '../../../../shared/hooks/hooks';

import { DtAddressData } from '../../../../shared/types/graphql-types';
import { ProductItem } from '../../../../shared/types/product';

interface StoreQuickViewCtasProps {
  address: DtAddressData;
  cartReconciliationWarningMessage?: string;
  isPitPassStore?: boolean;
  isStoreSelectionDisabled?: boolean;
  onChangeStore?: (changeStore: boolean) => void;
  onContinueClick?: () => void;
  onScheduleAppointmentClick?: () => void;
  onSelectStore?: (address: DtAddressData, storeCode: string) => void;
  onSetStoreClick?: (results: object) => void;
  onShopProductsClick?: () => void;
  productItem?: ProductItem;
  selectStoreButtonLabel?: string;
  shouldSetStoreAndCart?: boolean;
  showAppointmentCTA?: boolean;
  showContinueCTA?: boolean;
  showMakeMyStoreCTA?: boolean;
  showShopProductsCTA?: boolean;
  storeCode: string;
}

function StoreQuickViewCtas(props: StoreQuickViewCtasProps) {
  const {
    address,
    cartReconciliationWarningMessage,
    isPitPassStore = false,
    isStoreSelectionDisabled,
    onChangeStore,
    onContinueClick,
    onScheduleAppointmentClick,
    onSelectStore,
    onSetStoreClick,
    onShopProductsClick,
    productItem,
    selectStoreButtonLabel: selectStoreButtonLabelProp,
    shouldSetStoreAndCart = true,
    showAppointmentCTA,
    showContinueCTA,
    showMakeMyStoreCTA,
    showShopProductsCTA,
    storeCode
  } = props;

  const { setStore } = useMyStore();
  const makeThisMyStoreLabel = `MAKE ${
    isPitPassStore ? 'PIT PASS' : 'THIS'
  } MY STORE`;
  const selectStoreButtonLabel =
    selectStoreButtonLabelProp || makeThisMyStoreLabel;
  const { handleScheduleAppointment } = useScheduleAppointment();
  const { selectedFitment } = useFitment();

  const showCtas =
    showAppointmentCTA ||
    showMakeMyStoreCTA ||
    showShopProductsCTA ||
    showContinueCTA;

  async function setStoreAndCart() {
    onSelectStore?.(address, storeCode);

    let setStoreResult;
    if (shouldSetStoreAndCart) {
      try {
        setStoreResult = await setStore(storeCode, {
          cartReconciliationWarningMessage,
          fitment: selectedFitment,
          productItem,
          shouldConfirmStore: true,
          storeAddress: address
        });
      } catch (e) {
        console.log('Error setting store: ', e);
      }

      onChangeStore?.(false);
    }

    onSetStoreClick?.(setStoreResult);
  }

  return showCtas ? (
    <ButtonGroup layout="vertical">
      {showShopProductsCTA && (
        <Button
          href={
            selectedFitment ? '#/fitment/vehicle/results' : '#/fitment/vehicle'
          }
          isPrimary
          onClick={onShopProductsClick}
        >
          {isPitPassStore ? 'Shop Online At Pit Pass' : 'Shop Products'}
        </Button>
      )}
      {showContinueCTA && (
        <Button
          isPrimary
          onClick={() => {
            onContinueClick?.();
            onChangeStore?.(false);
          }}
        >
          {' '}
          Continue{' '}
        </Button>
      )}
      {showMakeMyStoreCTA && (
        <Button
          isDisabled={isStoreSelectionDisabled}
          isSecondary
          onClick={setStoreAndCart}
          type="submit"
        >
          {selectStoreButtonLabel}
        </Button>
      )}
      {showAppointmentCTA && (
        <Button
          isSecondary
          onClick={() => {
            handleScheduleAppointment(storeCode);
            onScheduleAppointmentClick?.();
          }}
        >
          Schedule Appointment
        </Button>
      )}
    </ButtonGroup>
  ) : null;
}

export default StoreQuickViewCtas;
