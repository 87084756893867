import React from 'react';
import PropTypes from 'prop-types';

import './show-hours.scss';

function ShowHours(props) {
  const { storeHours, className = '' } = props;
  const { monFriHours, satHours, sunHours } = storeHours || {};

  if (!storeHours) {
    return null;
  }

  return (
    <div className={`show-hours ${className}`} styleName="container">
      <span styleName="weekday-font">Mon-Fri:</span> {monFriHours}
      <br />
      <span styleName="weekday-font">Sat:</span> {satHours}
      <br />
      <span styleName="weekday-font">Sun:</span> {sunHours}
    </div>
  );
}

ShowHours.propTypes = {
  className: PropTypes.string,
  storeHours: PropTypes.shape({
    monFriHours: PropTypes.string,
    satHours: PropTypes.string,
    sunHours: PropTypes.string
  })
};

export default ShowHours;
