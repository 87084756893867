import React from 'react';

import './pitpass-logo.scss';

interface PitpassLogoProps {
  className?: string;
  isPitPassLogoOnly?: boolean;
}

function PitpassLogo(props: PitpassLogoProps): React.ReactElement {
  const { className, isPitPassLogoOnly = false } = props;

  return (
    <img
      alt="Pit Pass"
      className={className}
      src={`https://discounttire.scene7.com/is/content/discounttire/logos/DT_Pit-Pass_Logo-stand-alone${
        isPitPassLogoOnly ? '-original' : ''
      }.svg`}
      styleName="logo"
    />
  );
}

export default PitpassLogo;
