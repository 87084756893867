import React from 'react';

import Button from '../../library/button/button';
import {
  AngleDoubleLeftRegularIcon,
  AngleDoubleRightRegularIcon,
  AngleLeftRegularIcon,
  AngleRightRegularIcon
} from '../../shared/icons/icons';

import './pagination.scss';

interface PaginationProps {
  className?: string;
  currentPage?: number;
  numberOfPages?: number;
  numberOfPagesToShow?: number;
  onPageChange: (pageNumber: number) => void;
}

function Pagination(props: PaginationProps) {
  const {
    className,
    currentPage = 0,
    numberOfPages = 1,
    numberOfPagesToShow = 5,
    onPageChange
  } = props;

  function getPagesToDisplay(currentPage, numberOfPages, numberOfPagesToShow) {
    if (numberOfPagesToShow > numberOfPages) {
      return Array.from({ length: numberOfPages }, (_, pageNum) => pageNum);
    }

    let leftEdge = Math.max(
      0,
      Math.ceil(currentPage - numberOfPagesToShow / 2)
    );

    let rightEdge = Math.max(
      0,
      Math.ceil(currentPage + numberOfPagesToShow / 2)
    );

    //Shift the range right if it falls below zero
    if (leftEdge < 0) {
      rightEdge = rightEdge + Math.abs(leftEdge);
      leftEdge = 0;
    }

    //Shift the range left if it's above number of pages
    if (rightEdge > numberOfPages) {
      leftEdge = leftEdge - (rightEdge - numberOfPages);
      rightEdge = numberOfPages;
    }

    return Array.from({ length: numberOfPagesToShow }, (_, i) => leftEdge + i);
  }

  const pages = getPagesToDisplay(
    currentPage,
    numberOfPages,
    numberOfPagesToShow
  );

  // Don't show pagination if there is only one page of results
  if (numberOfPages === 1) return null;

  return (
    <div className={`${className || ''} pagination`} styleName="pagination">
      {currentPage > 0 && (
        <>
          <Button
            ariaLabel="First Page"
            onClick={() => onPageChange(0)}
            styleName="icon-wrapper"
          >
            <AngleDoubleLeftRegularIcon
              className="pagination__icon"
              data-testid="angle-double-left"
              styleName="page-number icon"
            />
          </Button>
          <Button
            ariaLabel="Previous Page"
            onClick={() => onPageChange(currentPage - 1)}
            styleName="icon-wrapper"
          >
            <AngleLeftRegularIcon
              className="pagination__icon"
              data-testid="angle-left"
              styleName="page-number icon"
            />
          </Button>
        </>
      )}

      {pages.map(page => (
        <Button
          ariaLabel={`page ${page + 1}`}
          className={`pagination__page-number ${
            page === currentPage ? 'pagination__page-number--selected' : ''
          }`}
          key={`pagination-count-${page}`}
          onClick={() => onPageChange(page)}
          size="small"
          styleName={
            page === currentPage ? 'page-number selected' : 'page-number'
          }
        >
          {page + 1}
        </Button>
      ))}

      {currentPage < numberOfPages - 1 && (
        <>
          <Button
            ariaLabel="Next Page"
            onClick={() => onPageChange(currentPage + 1)}
            styleName="icon-wrapper"
          >
            <AngleRightRegularIcon
              className="pagination__icon"
              data-testid="angle-right"
              styleName="page-number icon"
            />
          </Button>
          <Button
            ariaLabel="Last Page"
            onClick={() => onPageChange(numberOfPages - 1)}
            styleName="icon-wrapper"
          >
            <AngleDoubleRightRegularIcon
              className="pagination__icon"
              data-testid="angle-double-right"
              styleName="page-number icon"
            />
          </Button>
        </>
      )}
    </div>
  );
}

export default React.memo(Pagination);
