import React from 'react';

import {
  CheckSolidIcon,
  ExclamationTriangleSolidIcon,
  InfoCircleSolidIcon,
  TimesRegularIcon
} from '../../shared/icons/icons';

import './message.scss';

//TODO Need to update isWarning and isInfo into a type enum prop
interface MessageProps {
  className?: string;
  isAlignCenter?: boolean;
  isError?: boolean;
  isInfo?: boolean;
  isSolidInfo?: boolean;
  isSuccess?: boolean;
  isWarning?: Boolean;
  message?: React.ReactNode;
  onClose?: () => void;
  secondaryMessage?: React.ReactNode;
  showCloseBtn?: boolean;
}

function Message(props: MessageProps) {
  const {
    className = '',
    isAlignCenter,
    isError,
    isInfo,
    isSolidInfo,
    isSuccess,
    isWarning,
    message,
    onClose,
    secondaryMessage,
    showCloseBtn
  } = props;

  let styleName = 'message';
  if (isSuccess) styleName = 'success';
  if (isError) styleName = 'error';
  if (isWarning) styleName = 'warning';
  if (isInfo) styleName = 'info';
  if (isSolidInfo) styleName = 'solid-info';
  if (isAlignCenter) styleName += ' center';
  if (showCloseBtn) styleName += ' btn-padding';

  return (
    <div className={`message ${className}`} styleName={styleName}>
      {isSuccess && (
        <CheckSolidIcon
          className="message__icon message__error-icon"
          styleName="icon"
        />
      )}
      {isError && (
        <ExclamationTriangleSolidIcon
          className="message__icon message__error-icon"
          styleName="icon"
        />
      )}
      {isWarning && (
        <ExclamationTriangleSolidIcon
          className=" message__icon message__warning-icon"
          styleName="icon"
        />
      )}
      {(isInfo || isSolidInfo) && (
        <InfoCircleSolidIcon
          className="message__icon message__info-icon"
          styleName="icon"
        />
      )}
      {secondaryMessage ? (
        <div className="message__text-wrapper" styleName="text-wrapper">
          <div className="message__message">{message}</div>
          <div styleName="secondary-message">{secondaryMessage}</div>
        </div>
      ) : (
        <div className="message__message">{message}</div>
      )}
      {showCloseBtn && (
        <button
          aria-label="message close button"
          onClick={onClose}
          styleName="close-btn"
          type="button"
        >
          <TimesRegularIcon />
        </button>
      )}
    </div>
  );
}

export default Message;
